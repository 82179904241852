<template>
  <div>
    <Login/>
    <div class="zone-content-form wf-section" v-if="ready">
      <div class="container-content-form-suite w-container">
        <div class="w-layout-grid grid-form">
          <div class="div-precedent"></div>
          <div class="div-content-form">
            <p class="form-titre-page inscription-attestation">Connectez-vous à votre espace Pautions et téléchargez votre attestation provisoire.<span class="text-span-9"></span></p>
            <div class="form-inscription-locataire w-form">
              <p class="txt-inscription-locataire">
                Vous êtes : {{user.prenom}} {{user.nom}}<br>
                Né(e)  le : {{formatDate(user.date_naissance)}}<br>
                Mail : {{user.email}}<br>
                Téléphone : {{user.telephone}}
              </p>
              <form ref="form_mdp" class="form-attestation-complete" aria-label="Mail">
                <p class="form-second-titre">Merci de créer un mot de passe pour votre compte Pautions :<span class="text-span-9"></span></p>
                <div class="div-champs-form pass">
                  <p class="texte-info-form motdepasse">Mot de passe</p>
                  <div class="div-block-11">
                    <input v-model="user.password" type="text" class="champs-formulaire pass w-input" maxlength="256" name="Mot-de-passe" data-name="Mot de passe" placeholder="" id="Mot-de-passe" required="">
                    <img width="25" loading="lazy" :src="require('@/assets/images/ic_eye_open.svg')" alt="" class="mot-de-passe-visible"><img width="25" loading="lazy" :src="require('@/assets/images/ic_eye_open.svg')" alt="" class="mot-de-passe-non-visible">
                  </div>
                </div>
                <a v-if="loader === false" style="text-align:center" class="bouton-continuer w-button" @click="save()">
                  Se connecter
                </a>
                <div v-else style="text-align: center;">
                  <img :src="require('@/assets/images/spinner.svg')">
                  <p style="color:rgb(251, 177, 205);font-weight:bold;font-size:18px;">Merci de patienter pendant la génération de votre attestation provisoire.</p>
                </div>
              </form>
            </div>
            <div class="div-zone-info">
              <p class="text rose"><span class="text-span-11">C'est quoi l'espace Pautions ?</span></p>
              <p class="paragraph-14">L'espace Pautions vous permet de récupérer votre attestation provisoire et plus tard, souscrire à l'assurance Zéro Dépôt.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Login from '@/components/Menu/Login'
export default {
  name: "InscriptionProrprietaire",
  components: {
    Login
  },
  data(){
    return {
      ready:false,
      loader:false,
      user:{}
    }
  },
  beforeMount(){
    if(this.$route.query.uuid != undefined){
      this.$store.api.ajax('/users/locataire/'+this.$route.query.uuid, null, res => {
        if(res.status === true){
          this.user = res.data.user;
          this.user.password = null;
          this.ready = true;
        }
      })
    }
  },
  methods: {
    formatDate(date){
      return date.split('-').reverse().join('/');
    },
    checkForm(){
      this.user === this.user
      var res = true;
      if(this.$refs.form_mdp.reportValidity() === false){
        res = false;
      }
      if(this.optinn === false){
        res = false;
      }
      return res;
    },
    save(){
      if(this.checkForm() === false){
        return false;
      }
      var params = {
        uuid:this.$route.query.uuid,
        user:this.user
      }
      this.loader = true;
      this.$store.api.ajax('/users/locataire', params, res => {
        if(res.status === true){
          this.$store.api.saveToken(res.data.token);
          this.$store.api.saveUser(res.data);
          this.$router.push({path:'/inscription/telechargement?uuid='+this.$route.query.uuid});
        }
      });
    }
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-form";
  },
}
</script>
<style scoped>
@media only screen and (max-width: 600px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
